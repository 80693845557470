import { isProd } from '@/constants'
import { Web3Provider } from '@ethersproject/providers'
import { BundleItem, ChainType, EverpayTransaction, Token } from './everpay-js'
import { fromDecimalToUnit, genTokenTag, matchTokenTag, toBN, getTokenByTag, isArweaveL2PSTTokenSymbol } from './everpay-js/utils/util'
import { ElMessage } from 'element-plus'
import capitalize from 'lodash/capitalize'
import { getAccountByWindowEthereumAsync } from './chainLibAdaptor/ethereum'
import { ManualSubmitData } from './types'

export interface GetExplorerUrlParams {
  type: 'address' | 'tx' | 'token'
  value: string
  apply?: string
  symbol?: string
}

export const getEthereumExplorerUrl = (chainType: ChainType, params: GetExplorerUrlParams): string => {
  const { type, value, apply } = params
  let prefix = ''
  if (chainType === ChainType.ethereum) {
    prefix = isProd ? 'https://etherscan.io' : 'https://goerli.etherscan.io'
  } else if (chainType === ChainType.moon) {
    prefix = isProd ? 'https://moonscan.io' : 'https://moonbase.moonscan.io'
  } else if (chainType === ChainType.conflux) {
    prefix = isProd ? 'https://evm.confluxscan.net' : 'https://evmtestnet.confluxscan.net'
  } else if (chainType === ChainType.bsc) {
    prefix = isProd ? 'https://bscscan.com' : 'https://testnet.bscscan.com'
  } else if (chainType === ChainType.platon) {
    prefix = isProd ? 'https://scan.platon.network' : 'https://devnet2scan.platon.network'
  } else if (chainType === ChainType.mapo) {
    prefix = isProd ? 'https://maposcan.io' : 'https://testnet.maposcan.io'
  } else if (chainType === ChainType.psntest) {
    prefix = isProd ? 'https://scan.everpay.io' : 'https://scan-dev.everpay.io'
    const affix = type === 'tx' ? `tx/${value}` : `account/${value}`
    return `${prefix}/${affix}`
  }
  const affix = type === 'tx' ? `tx/${value}` : (type === 'token' ? `token/${value}?a=${apply as string}` : `address/${value}`)
  return `${prefix}/${affix}`
}

export const getArweaveExplorerUrl = (chainType: ChainType, params: GetExplorerUrlParams): string => {
  const { type, value, symbol = '' } = params
  if (isArweaveL2PSTTokenSymbol(symbol) && type === 'tx') {
    return `https://sonar.warp.cc/?#/app/interaction/${value}?network=mainnet`
  }
  if (chainType === ChainType.aostest) {
    const prefix = 'https://www.ao.link'
    const affix = type === 'address' ? `entity/${value}` : type === 'tx' ? `message/${value}` : `${value}`
    return `${prefix}/#/${affix}`
  }
  const prefix = 'https://viewblock.io/arweave'
  const affix = type === 'address' ? `address/${value}` : `tx/${value}`
  return `${prefix}/${affix}`
}
export const getExplorerUrl = (params: GetExplorerUrlParams, chainType: ChainType): string => {
  if (chainType === ChainType.arweave || chainType === ChainType.aostest) {
    return getArweaveExplorerUrl(chainType, params)
  }
  return getEthereumExplorerUrl(chainType, params)
}

const mobileRE = /(android|bb\d+|meego).+mobile|armv7l|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i

const tabletRE = /android|ipad|playbook|silk/i

export const isMobileDevice = (opts?: any): boolean => {
  if (opts == null) opts = {}
  let ua = opts.ua
  if (ua == null && typeof navigator !== 'undefined') ua = navigator.userAgent
  if (ua?.headers != null && typeof ua.headers['user-agent'] === 'string') {
    ua = ua.headers['user-agent']
  }
  if (typeof ua !== 'string') return false

  let result = mobileRE.test(ua) || (opts?.tablet != null && tabletRE.test(ua))

  if (
    !result &&
    opts?.tablet != null &&
    opts?.featureDetect != null &&
    navigator?.maxTouchPoints > 1 &&
    ua.includes('Macintosh') &&
    ua.includes('Safari')
  ) {
    result = true
  }

  return result
}

export const checkParentsHas = (classname: string) => {
  return (node: HTMLElement | null) => {
    while (node != null) {
      if (node?.classList?.contains(classname)) {
        return true
      }
      node = node.parentNode as HTMLElement | null
    }
    return false
  }
}

interface BundleItemFormat {
  from: string
  to: string
  amount: string
  symbol: string
}

export const getInternalRecordsFromBundleTx = (
  tokens: Token[],
  record: EverpayTransaction
): BundleItemFormat[] => {
  const dataObj = JSON.parse(record.data)
  const results: BundleItemFormat[] = []
  if (dataObj.bundle?.items?.length != null) {
    dataObj.bundle.items.forEach((item: BundleItem) => {
      const { from, to, tag, amount } = item
      const token = tokens.find((t: Token) => matchTokenTag(genTokenTag(t), tag))
      const symbol = token !== undefined ? token.symbol : tag.includes('-') ? tag.split('-')[1]?.toUpperCase() : 'undefined'
      const amountFormat = fromDecimalToUnit(amount, token !== undefined ? token?.decimals : 18)
      const formatedItem: BundleItemFormat = {
        from,
        to,
        symbol,
        amount: amountFormat
      }
      results.push(formatedItem)
    })
  }

  return results
}
/**
 * we don't use https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString here
 * cause toLocalString will auto round bigNumber
 * but now we used too many times bigNumber's method, this may caused some performance problem.
 */
export const thousandCommas = (num: number | string, place: number = 4): string => {
  if (place < 0 || place > 20) {
    console.warn('max must be less than 20')
    return toBN(num).toString(10)
  }

  // const n = toBN(num).toFormat(place, 1)
  const n = toBN(num).toFormat()
  /**
   *  小数位去零
   * 3.14159000 =>  3.14159
   * 3.00 => 3
   * 3.00012 => 3.00012
   * 3.0001200 => 3.00012
   * 31415 => 31,415
   */
  // return n.replace(/\.0+$/g, '').replace(/\.(.*[^0])0+$/g, '.$1')
  return n
}

// only format money
export const formatMoney = (value: number | string, place: number = 2): string => {
  return thousandCommas(value, place)
}

export const formatNumber = (value: string, place: number = 5): string => {
  const totalAmount = formatMoney(value)
  let index = 0
  let amount = ''
  for (let i = 0; i < totalAmount.length; i++) {
    if (index === place) {
      break
    }
    if (totalAmount[i] === ',' || totalAmount[i] === '.') {
      amount = amount + totalAmount[i]
      continue
    }
    index = index + 1
    amount = amount + totalAmount[i]
  }
  return amount
}

export const formatTargetChainType = (txInfo: any): string => {
  if (txInfo.action !== 'burn' && txInfo.action !== 'mint') {
    return ''
  }

  try {
    const targetChainType = JSON.parse(txInfo.data)?.targetChainType
    if (targetChainType != null) {
      return targetChainType
    }
  } catch {}
  if (txInfo.chainType.includes('arweave') === true) {
    return 'arweave'
  }
  if (txInfo.chainType === 'moonbase' || txInfo.chainType === 'moonbeam') {
    return 'moon'
  }
  return txInfo.chainType
}
interface TokenTagInfo {
  tokenID: string
  tokenSymbol: string
  chainType: string
  tag: string
}
export const formatTokenTag = <T extends TokenTagInfo>(token: T, tokenList: Token[]): Token | undefined => {
  const { tokenID = '', tokenSymbol = '', chainType = '', tag = '' } = token ?? {}
  const tokenByTag = getTokenByTag(tag, tokenList)
  if (tokenByTag != null) {
    return tokenByTag
  } else {
    const tokenTag = [chainType, tokenSymbol, tokenID].join('-')
    return getTokenByTag(tokenTag, tokenList)
  }
}

const getErrorMessage = (e: Error, t: any): string => {
  let message = ''
  if (e.message.includes('Network Error') || e.message.includes('Error: timeout')) {
    message = t('network_error')
  } else {
    message = t(e.message)
  }
  return message
}
export const handleErrorMsg = (e: Error, t: any): void => {
  ElMessage({
    showClose: true,
    message: getErrorMessage(e, t),
    type: 'error'
  })
}

export const formatChainTypeDisplay = (chainType: ChainType | string): string => {
  switch (chainType) {
    case ChainType.mapo:
      return 'MAP protocol'
    case ChainType.bsc:
      return 'BSC'
    case ChainType.platon:
      return 'PlatON'
    case ChainType.moon:
      return isProd ? 'Moonbeam' : 'Moonbase'
    default:
      return capitalize(chainType.includes(',') ? chainType.split(',')[0] : chainType)
  }
}

const checkSum = async (idBytes: Uint8Array): Promise<Uint8Array> => {
  const hash = await crypto.subtle.digest('SHA-256', Uint8Array.from([...Buffer.from('eid'), ...idBytes]))
  return new Uint8Array(hash).slice(0, 2)
}

export const uint8ArrayToHex = (uint8Array: Uint8Array): string => {
  return [...uint8Array].map((b) => {
    return b.toString(16).padStart(2, '0')
  }).join('')
}

export const hexToUint8Array = (hexString: string): Uint8Array =>
  Uint8Array.from((hexString.match(/.{1,2}/g) as any).map((byte: any) => parseInt(byte, 16)))

export const genEverId = async (email: string): Promise<string> => {
  const str = email.toLowerCase().trim()
  const hash = await crypto.subtle.digest('SHA-256', Buffer.from(str, 'utf-8'))
  const idBytes = new Uint8Array(hash)
  const sum = await checkSum(idBytes)
  const concatArray = Uint8Array.from([...idBytes, ...sum])
  return `eid${uint8ArrayToHex(concatArray)}`
}

export const isSmartAccount = (account: string): boolean => {
  return /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(account)
}

export const connectMetamask = async (manualSubmitData: ManualSubmitData | null): Promise<string> => {
  if (manualSubmitData !== null && (manualSubmitData.onChainStatus === 'manual' || manualSubmitData.onChainStatus === 'failed')) {
    const account = await getAccountByWindowEthereumAsync({
      connectAppName: 'Metamask',
      chainType: manualSubmitData.chainType as ChainType
    })
    return account
  }
  return ''
}
export const sendTransaction = async (manualSubmitData: ManualSubmitData | null): Promise<any> => {
  if (manualSubmitData !== null && window.ethereum.selectedAddress !== '') {
    const provider = new Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const result = await signer.sendTransaction({
      to: manualSubmitData.to,
      gasLimit: '0x7a120',
      // gasPrice: '0x9184e72a000', // 10000000000000
      // value: '0x9184e72a', // 2441406250
      data: manualSubmitData.callData
    })
    return result
  }
  return undefined
}
